<template>
  <div>
    <!-- Budget-->
    <vx-card class="mb-8">
      <div class="card-title more-card-title flex justify-between items-center">
        <div class="flex items-center flex-wrap">
          <h2 class="w-auto mb-2">Budget</h2>
        </div>
      </div>
      <vx-card class="mb-8">
        <div class="card-title budget-title">
          <h4 class="w-auto mb-2">Current Pay Period</h4>
          <p class="d-blok">{{ bugdetSettings? bugdetSettings.PayPeriod:"" }}</p>
          <div class="budget-card mt-5">
            <div class="vx-row">
              <div
                class="vx-col xl:w-2/4 mb-8 md:w-1/2 w-full lg-cus-6"
                id="v-step-0"
              >
                <vx-card>
                  <div class="budget-title">
                    <span class="material-icons text-first">
                      monetization_on
                    </span>
                    <h2>{{ totalSpendData?totalSpendData.StatName:"" }}</h2>
                  </div>
                  <h3 class="text-first">${{ totalSpendData?totalSpendData.StatUsedValue:0 }}</h3>
                  <apexchart
                    type="radialBar"
                    height="260"
                    :options="totalSpendOptions"
                    :series="totalSpendSeries"
                  ></apexchart>
                  <div class="currently-budget">
                    <!-- <span>Current Monthly Budget: ${{ totalSpendData?totalSpendData.StatTotalValue:0 }}</span>  -->
                    <span class="border-top">Current Monthly Budget: ${{ totalSpendData?totalSpendData.StatTotalValue:0 }}</span> 
                    <!-- <span class="border-top">Previous Period: ${{ totalSpendData?totalSpendData.PerviousActual:0 }}</span>  -->
                  </div>
                </vx-card>
              </div>
              <div
                class="vx-col xl:w-2/4 mb-8 md:w-1/2 w-full lg-cus-6"
                id="v-step-1"
              >
                <vx-card>
                  <div class="budget-title">
                    <span class="material-icons text-second">
                      supervisor_account
                    </span>
                    <h2>{{ totalIdentifiedVisitorsData?totalIdentifiedVisitorsData.StatName:"" }}</h2>
                  </div>
                  <h3 class="text-second">{{ totalIdentifiedVisitorsData?totalIdentifiedVisitorsData.StatUsedValue:0 }}</h3>
                  <apexchart
                    type="radialBar"
                    height="260"
                    :options="totalIdentifiedVisitorsOptions"
                    :series="totalIdentifiedVisitorsSeries"
                  ></apexchart>
                  <div class="currently-budget">
                    <span class="border-top">Previous Period: {{ totalIdentifiedVisitorsData?totalIdentifiedVisitorsData.PerviousActual:0 }}</span> 
                    <!-- <span >Current Budget: {{ totalIdentifiedVisitorsData?totalIdentifiedVisitorsData.PerviousActual:0 }}</span>  -->
                  </div>
                </vx-card>
                
              </div>
            </div>
            <div class="budget-range">  
                <div class="input-custom">
                  <vs-input v-model="customerCap"></vs-input>
                  <label for="">Customer Cap for Identified Visitors per month</label>
                </div>
                <div class="centerx fliter-slider">
                  <div class="cuadrox start">
                   {{customerStartCap}}
                  </div>
                  <!-- <vs-slider :min="bugdetSettings? bugdetSettings.CommittedNumberofVisitors:50" :max="bugdetSettings?bugdetSettings.OverageCap:5000" @change="cambio" color="rgb(45, 208, 129)" v-model="customerCap"/> -->
                  <vue-slider
                      v-model="customerCap"
                      :min="customerStartCap"
                      :max="customerEndCap"
                      :process="false"
                      :tooltip="'always'"
                      :height="6"
                      class="mr-4"
                      :direction="'horizontal'"
                      :clickable="false"
                      @drag-end="changeSliderValue"
                    />
                  <div class="cuadrox end">
                    {{customerEndCap}}
                  </div>
                </div>
            </div>
            <div class="vx-row">
              <div
                class="vx-col xl:w-2/4 md:w-1/2 w-full lg-cus-6"
                id="v-step-0"
              >
                  <div class="currently-budget">
                    <span class="mb-4">Base monthly usage ${{ bugdetSettings? bugdetSettings.BaseMonthlyUsage:"" }}</span> 
                    <span class="mb-4">Identified Visitors  included in Plan {{ bugdetSettings ?bugdetSettings.CommittedNumberofVisitors:"" }}</span> 
                  </div>
              </div>
              <div
                class="vx-col xl:w-2/4 md:w-1/2 w-full lg-cus-6"
                id="v-step-1"
              >
                  <div class="currently-budget">
                    <span class="mb-4">System Cap for Identified Visitors per Month {{ bugdetSettings? bugdetSettings.OverageCap:"" }}</span> 
                    <span class="mb-4">Rate of Identified Visitor above Plan Commitment ${{ bugdetSettings? bugdetSettings.OveragePrice:"" }}</span> 
                  </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </vx-card>
    <vs-popup class="delete-popup delete-range" :active.sync="confirmationPopupActive">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <div class="popup-icon size-50 text-danger">
                        <feather-icon icon="AlertCircleIcon"></feather-icon>
                    </div>
                    <p>Are you sure you want to change customer cap for identified visitors per month</p>
                </div>
            </div>
        </template>
        <div class="mt-3 mb-8">
            <vs-button color="primary" type="filled" @click="updateCurrentSettings()">{{ LabelConstant.buttonLabelYes }}</vs-button>
            <vs-button color="grey" type="border" class="ml-4 grey-btn" @click="confirmationPopupActive=false,CurrentCap = bugdetSettings.CurrentCap">{{ LabelConstant.buttonLabelNo }}</vs-button>
        </div>
    </vs-popup>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import vueSlider from "vue-slider-component";

export default {
  components: {
    apexchart: VueApexCharts,
    vueSlider
  },
  data() {
    return {
      totalSpendData:null,
      totalSpendSeries: [33],
      totalSpendOptions: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: 0,
            dataLabels: {
              show: true,
              name: {
                  show: false,
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 10,
                  formatter: function (val) {
                    return val + '%'
                  }
                },

            },
          },
        },
        colors: ["#044d80"],
      },
      totalIdentifiedVisitorsData:null,
      totalIdentifiedVisitorsSeries: [50],
      totalIdentifiedVisitorsOptions: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: 0,
            dataLabels: {
              show: true,
              name: {
                  show: false,
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 10,
                  formatter: function (val) {
                    return val + '%'
                  }
                },

            },
          },
        },
        colors: ["#7ac546"],
      },
      value1:55,
      widthx:55,
      heightx:55,
      bugdetSettings:null,
      customerCap:0,
      customerStartCap:0,
      customerEndCap:5000,
      confirmationPopupActive:false,
    };
  },
  created(){
    this.getTotalSpend();
    this.getTotalIdentifiedVisitors();
    this.getCurrentSettings();
  },
  methods:{
    cambio(value){
      this.widthx = value
      this.heightx = value
    },
    getTotalSpend(){
      this.$vs.loading();
      this.axios
        .get("ws/ConsumerBudget/GetTotalSpend")
        .then(response => {
          let data = response.data;
          let perc = 0;
          perc = ((data.StatUsedValue/data.StatTotalValue) * 100);
          this.totalSpendSeries = [parseInt(perc)];

          this.totalSpendData = data;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getTotalIdentifiedVisitors(){
      this.$vs.loading();
      this.axios
        .get("ws/ConsumerBudget/GetTotalVisitors")
        .then(response => {
          let data = response.data;
          let perc = 0;
          perc = ((data.StatUsedValue/data.StatTotalValue) * 100);
          this.totalIdentifiedVisitorsSeries = [parseInt(perc)];
          this.totalIdentifiedVisitorsData = data;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getCurrentSettings(){
      this.$vs.loading();
      this.axios
        .get("/ws/ConsumerBudget/GetCurrentSettings")
        .then(response => {
          let data = response.data;
          this.bugdetSettings = data;
          this.customerStartCap = data.CommittedNumberofVisitors;
          this.customerEndCap = data.OverageCap;
          this.customerCap = data.CurrentCap;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    updateCurrentSettings(){
        let updateCurrentParams = {     
          "CommittedNumberofVisitors": this.customerStartCap,
          "BaseMonthlyUsage": this.bugdetSettings.BaseMonthlyUsage,
          "OveragePrice": this.bugdetSettings.OveragePrice,
          "OverageCap": this.customerEndCap,
          "CurrentCap": this.customerCap,
          "PayPeriod": this.bugdetSettings.PayPeriod,

      };
      this.$vs.loading();
      this.axios
        .post(
          "/ws/ConsumerBudget/UpdateCurrentSettings",
          updateCurrentParams
        )
        .then(response => {
          this.confirmationPopupActive = false;
          let data = response.data;
          this.bugdetSettings = data;
          this.customerStartCap = data.CommittedNumberofVisitors;
          this.customerEndCap = data.OverageCap;
          this.customerCap = data.CurrentCap;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    changeSliderValue(){
     this.confirmationPopupActive = true; 
    }
  }
};
</script>